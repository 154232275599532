/** @format */

import React, { useState, useEffect } from "react";

function AllPayment() {
  const [allowanceData, setAllowanceData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/get-all-allowance`
        );

        if (response.ok) {
          const data = await response.json();
          console.log("Fetched data:", data); // Log the fetched data
          // Ensure data is an array
          if (Array.isArray(data)) {
            setAllowanceData(data);
          } else if (data.data && Array.isArray(data.data)) {
            setAllowanceData(data.data);
          } else {
            console.error("Unexpected data format:", data);
            setAllowanceData([]);
          }
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error occurred while fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchDesignations = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/designations/getdesignation`
        );

        if (response.ok) {
          const data = await response.json();
          const departments = [...new Set(data.map((item) => item.department))];
          setDepartments(departments);
          setDesignations(data);
        } else {
          console.error("Failed to fetch designations");
        }
      } catch (error) {
        console.error("Error occurred while fetching designations:", error);
      }
    };

    fetchDesignations();
  }, []);

  const filteredAllowanceData = allowanceData.filter((item) => {
    return (
      item.employee_no.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedDepartment === "" ||
        item.employee_department === selectedDepartment) &&
      (selectedDesignation === "" ||
        item.employee_designation === selectedDesignation)
    );
  });

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="font-sans text-[#797C80] text-[32px] ml-6 mt-6 font-bold">
          All Allowance
        </p>
      </div>

      <div className="mt-6">
        <div>
          <div className="flex justify-between items-center">
            <div>
              <div className="flex flex-col">
                <input
                  type="text"
                  placeholder="Search by Emp No..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400 ml-6"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col font-sans font-bold ml-6 rounded-xl mt-10">
            <table className="table-auto border-none w-[95%]">
              <thead className="sticky top-0">
                <tr className="bg-white">
                  <th className="text-primary px-4 py-2">Emp NO</th>
                  <th className="text-primary px-4 py-2">Allowance TP</th>
                  <th className="text-primary px-4 py-2">AJT Allowance</th>
                  <th className="text-primary px-4 py-2">Cost Of Allowance</th>
                  <th className="text-primary px-4 py-2">SLADC Allowance</th>
                  <th className="text-primary px-4 py-2">Salary Arrears</th>
                  <th className="text-primary px-4 py-2">Traveling</th>
                  <th className="text-primary px-4 py-2">Traveling Fuel</th>
                  <th className="text-primary px-4 py-2">Spe Allowance</th>
                  <th className="text-primary px-4 py-2">
                    Difficult Allowance
                  </th>
                  <th className="text-primary px-4 py-2">Act Allowance</th>
                  <th className="text-primary px-4 py-2">Acct Allo Arre</th>
                  <th className="text-primary px-4 py-2">SPE ALLOWANCE 02</th>
                  <th className="text-primary px-4 py-2">Language Allowance</th>
                  <th className="text-primary px-4 py-2">
                    Cost of Allowance Language
                  </th>
                  <th className="text-primary px-4 py-2">Over Time</th>
                </tr>
              </thead>
              <tbody>
                {filteredAllowanceData.map((item, index) => (
                  <tr key={index} className="bg-white">
                    <td className="px-4 py-2">{item.employee_no}</td>
                    <td className="px-4 py-2">{item.Allowance_TP}</td>
                    <td className="px-4 py-2">{item.AJT_Allowance}</td>
                    <td className="px-4 py-2">{item.Cost_Of_Allowance}</td>
                    <td className="px-4 py-2">{item.SLADC_Allowance}</td>
                    <td className="px-4 py-2">{item.Salary_Arrears}</td>
                    <td className="px-4 py-2">{item.Traveling}</td>
                    <td className="px-4 py-2">{item.Traveling_Fuel}</td>
                    <td className="px-4 py-2">{item.Spe_Allowance}</td>
                    <td className="px-4 py-2">{item.Difficult_Allowance}</td>
                    <td className="px-4 py-2">{item.Act_Allowance}</td>
                    <td className="px-4 py-2">{item.Acct_Allo_Arre}</td>
                    <td className="px-4 py-2">{item.SPE_ALLOWANCE_02}</td>
                    <td className="px-4 py-2">{item.language_allowance}</td>
                    <td className="px-4 py-2">
                      {item.cost_of_allowance_language}
                    </td>
                    <td className="px-4 py-2">{item.Over_Time}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllPayment;
