

import React from 'react';

const leavesData = [
  { name: "Total Workforce", color: '#5AB2FF' },
  { name: "Present Workforce", color: '#A0DEFF' },
  { name: "Absent Workforce", color: '#CAF4FF' },
  { name: "Late Arrivals", color: '#1E2A5E' },
  { name: "In Leave", color: '#7C93C3' },
];

const LeaveDetails = () => {
  return (
    <div className="order-2 border-gray-100 shadow-xl bg-white p-4 w-[200px] rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Employee Information</h2>
      <ul>
        {leavesData.map((leave, index) => (
          <li key={index} className="flex items-center mb-2">
            <span className={`w-4 h-4 rounded-full mr-2`} style={{backgroundColor: `${leave.color}`}} />
            <span>{leave.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeaveDetails;
