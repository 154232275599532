import React, { useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Emp_Chart = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const chartData = [
    { name: "Total Workforce", value: data.totalWorkforce, fill: "#5AB2FF" },
    {
      name: "Present Workforce",
      value: data.presentWorkforce,
      fill: "#A0DEFF",
    },
    { name: "Absent Workforce", value: data.absentWorkforce, fill: "#CAF4FF" },
    { name: "Late Arrivals", value: data.lateArrivals, fill: "#1E2A5E" },
    { name: "In Leave", value: data.inLeave, fill: "#7C93C3" },
  ];

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(-1);
  };

  return (
    <ResponsiveContainer width="100%" height={500}>
  
      <PieChart>
        <Pie
          data={chartData}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={150} // Base outer radius
          innerRadius={0} // Fully filled pie chart
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
        >
          {chartData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.fill}
              stroke="#fff"
              strokeWidth={index === activeIndex ? 5 : 1}
              outerRadius={index === activeIndex ? 170 : 150} // Emboss effect on hover
              cursor="pointer"
            />
          ))}
        </Pie>
        
        <Tooltip
          formatter={(value, name, props) => {
            const percent = (
              (props.payload.value / data.totalWorkforce) *
              100
            ).toFixed(2);
            return `${percent}% (${value} Employees)`;
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default Emp_Chart;
