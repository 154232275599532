/** @format */

import React, { useState, useEffect } from "react";
import usePermissions from "../../../../components/permissions/permission";

const Overtime = () => {
  const { hasPermission } = usePermissions();
  const [payrollData, setPayrollData] = useState([]);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [filterMonth, setFilterMonth] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;

  const fetchOvertimeSummary = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/overtime-summary`
      );
      const data = await response.json();
      setPayrollData(data);
      console.log("payrolldata:", data);
    } catch (error) {
      console.error("Error fetching overtime summary:", error);
      setErrorMessage({
        fetch: "Failed to fetch overtime summary. Please try again later.",
      });
    }
  };

  useEffect(() => {
    fetchOvertimeSummary();
  }, [REACT_APP_API_ENDPOINT]);

  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage({});
  };

  const handleCalculateSubmit = async () => {
    const body = {
      month: parseInt(month),
      year: parseInt(year),
    };

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/calculate-total-leave-amount`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();

      if (response.ok) {
        setIsModalOpen(false);
        fetchOvertimeSummary(); // Refresh the data after calculation
      } else {
        console.error(data.error || data.message);
        setErrorMessage({
          calculate: data.error || data.message,
        });
      }
    } catch (error) {
      console.error("Error calculating overtime:", error);
      setErrorMessage({
        calculate: "Failed to calculate overtime. Please try again later.",
      });
    }
  };

  const filteredData = payrollData.filter(
    (item) =>
      (!filterMonth || item.month_year.split("-")[0] === filterMonth) &&
      (!filterYear || item.month_year.split("-")[1] === filterYear)
  );

  return (
    <div className="font-sans bg-background overflow-auto h-screen">
      <div>
        <p className="mt-6 ml-6 text-headerColor text-[30px] font-semibold">
          Calculate Overtime
        </p>
      </div>
      <div className="flex justify-between items-center ml-10">
        <div className="flex flex-col">
          <select
            id="monthSelect"
            name="month"
            value={filterMonth}
            onChange={(e) => setFilterMonth(e.target.value)}
            autoComplete="off"
            className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white border border-black placeholder-white sm:text-sm px-2 font-sans mb-3"
          >
            <option value="" className="font-sans-bold">
              Filter By Month
            </option>
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          <select
            id="yearSelect"
            name="year"
            value={filterYear}
            onChange={(e) => setFilterYear(e.target.value)}
            autoComplete="off"
            className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white border border-black sm:text-sm px-2 font-sans"
          >
            <option value="" className="font-sans-bold">
              Filter By Year
            </option>
            {Array.from({ length: 10 }, (_, i) => {
              const year = new Date().getFullYear() - i;
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              );
            })}
          </select>
        </div>
        <div className="bg-[#5AB2FF] rounded-[22px] h-[189px] w-[430px] p-5 mb-5 mr-10">
          <div className="text-white font-bold text-[29px] mb-5">
            <p>Calculate Overtime</p>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-white p-2 mt-10 rounded-[32px] text-[#5AB2FF] cursor-pointer"
              onClick={() => setIsModalOpen(true)}
            >
              + Calculate
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col font-sans font-bold ml-6 mt-[50px] rounded-xl">
        <table className="table-auto border-none w-[95%] rounded-2xl border-2">
          <thead>
            <tr className="bg-gray-300 rounded-xl">
              <th className="text-primary px-4 py-2">Employee No</th>
              <th className="text-primary px-4 py-2">Full Name</th>
              <th className="text-primary px-4 py-2">Month Year</th>
              <th className="text-primary px-4 py-2">Total OT Hours</th>
              <th className="text-primary px-4 py-2">OT Amount</th>
              <th className="text-primary px-4 py-2">Department</th>
              <th className="text-primary px-4 py-2">Designation</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={item.id}>
                <td className="bg-white px-4 py-2 text-center">
                  {item.employee_no}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {item.employee_fullname}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {item.month_year}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {item.total_ot_hours}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {item.ot_amount}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {item.department}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {item.designation}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
          <div className="bg-[#F3F8FF] p-6 rounded-[40px]">
            <div className="mb-6 items-center justify-center">
              <p className="font-sans text-[32px] font-bold text-center">
                Calculate Overtime
              </p>
              <div className="mt-5 flex flex-col">
                <div className="flex flex-row items-center">
                  <div>
                    <p className="font-sans font-semibold">Month :</p>
                  </div>
                  <div className="ml-6">
                    <input
                      type="number"
                      min="1"
                      max="12"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                      className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    />
                    {errorMessage.month && (
                      <p className="text-red-500 text-sm mt-1">
                        {errorMessage.month}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-center mt-4">
                  <div>
                    <p className="font-sans font-semibold">Year :</p>
                  </div>
                  <div className="ml-6">
                    <input
                      type="number"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                      className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    />
                    {errorMessage.year && (
                      <p className="text-red-500 text-sm mt-1">
                        {errorMessage.year}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-5 space-x-3">
                <button
                  className="bg-primary p-2 rounded-[22px] text-white"
                  onClick={handleCalculateSubmit}
                >
                  Submit
                </button>
                <button
                  className="bg-gray-500 p-2 rounded-[22px] text-white"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Overtime;
