import React, { useState, useEffect } from "react";
import usePermissions from "../../../../components/permissions/permission";
import * as XLSX from "xlsx";

const MonthEndPayroll = () => {
  const { hasPermission } = usePermissions();
  const [employeeId, setEmployeeId] = useState("");
  const [payrollData, setPayrollData] = useState([]);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [generateYear, setGenerateYear] = useState("");
  const [generateMonth, setGenerateMonth] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = async () => {
    const endpoint = `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/payroll/monthly/filter?year=${year}&month=${month}`;
    try {
      const response = await fetch(endpoint);
      if (response.ok) {
        const data = await response.json();
        setPayrollData(data.data);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch payroll data:", errorData);
      }
    } catch (error) {
      console.error("Error occurred while fetching payroll data:", error);
    }
  };

  const handleFetchData = () => {
    if (year && month) {
      fetchData();
    } else {
      alert("Please select both year and month.");
    }
  };

  const filteredData = payrollData.filter((item) =>
    item.employee_no.includes(employeeId)
  );

  const handleGenerate = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openConfirmModal = () => {
    setIsModalOpen(false);
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirmGenerate = async () => {
    const endpoint = `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/payroll-genarate`;
    const body = {
      month: generateMonth,
      year: generateYear,
    };

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Payroll generated successfully:", data);
        closeConfirmModal();
        fetchData();
      } else {
        const errorData = await response.json();
        console.error("Failed to generate payroll:", errorData);
      }
    } catch (error) {
      console.error("Error occurred while generating payroll:", error);
    }
  };

  const exportToExcel = () => {
    if (!month || !year) {
      setErrorMessage("Please select both year and month before exporting.");
      return;
    }

    const exportData = payrollData.map((item) => ({
      "Employee No": item.employee_no,
      Month: item.month,
      Year: item.year,
      "Basic Salary": item.basic_salary,
      "AJT Allowance": item.ajt_allowance,
      "Cost of Allowance": item.cost_of_allowance,
      "SLADC Allowance": item.sladc_allowance,
      "Salary Arrears": item.salary_arrears,
      "Traveling Fuel": item.traveling_fuel,
      "SPE Allowance 01": item.spe_allowance_01,
      "Difficult Allowance": item.difficult_allowance,
      "ACT Allowance": item.act_allowance,
      "Allowance TP": item.allowance_tp,
      "Acct Allo Arre": item.acct_allo_arre,
      "SPE Allowance 02": item.spe_allowance_02,
      "Language Allowance": item.language_allowance,
      "Total Allowances": item.total_allowances,
      "Gross Pay": item.gross_pay,
      "No Pay": item.no_pay,
      "No Pay Amount": item.no_pay_amount,
      "Gross Pay After No Pay": item.gross_pay_after_no_pay,
      "Gross Pay for EPF": item.gross_pay_for_epf,
      "Salary for PAYE": item.salary_for_paye,
      "EPF 8%": item.epf_8_percent,
      "Pay Tax": item.pay_tax,
      "Stamp Duty": item.stamp_duty,
      "Salary Advance": item.salary_advance,
      "Welfare Members": item.welfare_members,
      "Festival Advance": item.festival_advance,
      "Bank Loan 01": item.bank_loan_01,
      "Other Deductions 1": item.other_deductions_1,
      "Welfare Advance": item.welfare_advance,
      "Union 1": item.union_1,
      "Union 2": item.union_2,
      "Union 3": item.union_3,
      "Union 4": item.union_4,
      "Union 5": item.union_5,
      "Buddha Sangamaya Members": item.buddha_sangamaya_members,
      "Sanasa Loan": item.sanasa_loan,
      "Other Deductions 2": item.other_deductions_2,
      "Welfare Loan": item.welfare_loan,
      "Saving to Bank": item.saving_to_bank,
      "Sanasa Member": item.sanasa_member,
      "Sanasa Advance": item.sanasa_advance,
      Donations: item.donations,
      "Other Deductions 3": item.other_deductions_3,
      "Bank Loan 02": item.bank_loan_02,
      "Distress Loan": item.distress_loan,
      "Distress Loan Interest": item.distress_loan_interest,
      "Total Deductions": item.total_deductions,
      "Net Salary": item.net_salary,
      "EPF 12%": item.epf_12_percent,
      "ETF 3%": item.etf_3_percent,
      "EPF 20%": item.epf_20_percent,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Payroll Data");

    // Generate Excel file and download
    XLSX.writeFile(workbook, `MonthEndPayroll_${month}_${year}.xlsx`);
  };

  return (
    <div className="font-sans bg-background fixed h-screen">
      <div>
        <p className="mt-6 ml-6 text-headerColor text-[30px] font-semibold">
          Month End Payroll
        </p>
      </div>
      <div className="flex justify-end">
        {hasPermission(2062) && (
          <div className="bg-white rounded-[22px] h-[189px] w-[430px] mr-[5%]">
            <div className="text-[#797C80] font-bold ml-4 mt-4 text-[29px]">
              <p>Generate</p>
              <p>Monthly Payroll</p>
            </div>
            <div className="flex items-center justify-between">
              <button
                type="button"
                className="bg-primary mt-5 ml-4 p-2 rounded-[32px] text-white cursor-pointer w-[151px] h-[37px]"
                onClick={handleGenerate}
              >
                Generate
              </button>
              <div className="mr-5">
                <div className="bg-primary rounded-[32px] h-[37px] w-[13px]"></div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="ml-6 mt-4">
        <div className="flex flex-col font-poppins font-bold">
          <form className="form relative mb-4">
            <input
              type="text"
              required=""
              placeholder="Search Employee ID"
              className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-black focus:outline-none focus:border-blue-500 placeholder-gray-400"
              value={employeeId}
              onChange={(e) => setEmployeeId(e.target.value)}
            />
          </form>
          <div className="flex mb-4">
            <select
              className="input rounded-full w-[200px] h-[5%] px-8 py-3 border-2 border-black focus:outline-none focus:border-blue-500 placeholder-gray-400 mr-4"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="">Select Year</option>
              {Array.from({ length: 10 }, (_, i) => {
                const year = new Date().getFullYear() - i;
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>
            <select
              className="input rounded-full w-[200px] h-[5%] px-8 py-3 border-2 border-black focus:outline-none focus:border-blue-500 placeholder-gray-400"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
            >
              <option value="">Select Month</option>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
            <button
              className="bg-primary text-white px-4 py-2 rounded-full ml-4"
              onClick={handleFetchData}
            >
              Get Payroll Data
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          className="bg-primary mt-5 ml-4 p-2 rounded-[32px] text-white text-bold  cursor-pointer w-[151px] h-[42px] mr-10"
          onClick={exportToExcel}
        >
          Export to Excel
        </button>
      </div>
      <div className="flex justify-end mr-7">
        {errorMessage && (
          <div className="text-red-500 font-semibold">{errorMessage}</div>
        )}
      </div>
      <div className="flex flex-col font-header w-full overflow-x-auto font-bold ml-6 mt-[50px] rounded-xl">
        <div className="h-[400px] overflow-y-auto">
          <table className="table-auto border-none rounded-3xl border-2 w-full overflow-y-auto mr-[60%]">
            {/* Table headers */}
            <thead className=" sticky top-0">
              <tr className="bg-gray-300">
                <th className="text-primary px-2 py-1 text-sm">#</th>
                <th className="text-primary px-2 py-1 text-sm">EMP NO</th>
                <th className="text-primary px-2 py-1 text-sm">Month</th>
                <th className="text-primary px-2 py-1 text-sm">Year</th>
                <th className="text-primary px-2 py-1 text-sm">Basic Salary</th>
                <th className="text-primary px-2 py-1 text-sm">
                  AJT Allowance
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  Cost of Allowance
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  SLADC Allowance
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  Salary Arrears
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  Traveling Fuel
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  SPE Allowance 01
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  Difficult Allowance
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  ACT Allowance
                </th>
                <th className="text-primary px-2 py-1 text-sm">Allowance TP</th>
                <th className="text-primary px-2 py-1 text-sm">
                  Acct Allo Arre
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  SPE Allowance 02
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  Language Allowance
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  Total Allowances
                </th>
                <th className="text-primary px-2 py-1 text-sm">Gross Pay</th>
                <th className="text-primary px-2 py-1 text-sm">No Pay</th>
                <th className="text-primary px-2 py-1 text-sm">
                  No Pay Amount
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  Gross Pay After No Pay
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  Gross Pay for EPF
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  Salary for PAYE
                </th>
                <th className="text-primary px-2 py-1 text-sm">EPF 8%</th>
                <th className="text-primary px-2 py-1 text-sm">Pay Tax</th>
                <th className="text-primary px-2 py-1 text-sm">Stamp Duty</th>
                <th className="text-primary px-2 py-1 text-sm">
                  Salary Advance
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  Welfare Members
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  Festival Advance
                </th>
                <th className="text-primary px-2 py-1 text-sm">Bank Loan 01</th>
                <th className="text-primary px-2 py-1 text-sm">
                  Other Deductions 1
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  Welfare Advance
                </th>
                <th className="text-primary px-2 py-1 text-sm">Union 1</th>
                <th className="text-primary px-2 py-1 text-sm">Union 2</th>
                <th className="text-primary px-2 py-1 text-sm">Union 3</th>
                <th className="text-primary px-2 py-1 text-sm">Union 4</th>
                <th className="text-primary px-2 py-1 text-sm">Union 5</th>
                <th className="text-primary px-2 py-1 text-sm">
                  Buddha Sangamaya Members
                </th>
                <th className="text-primary px-2 py-1 text-sm">Sanasa Loan</th>
                <th className="text-primary px-2 py-1 text-sm">
                  Other Deductions 2
                </th>
                <th className="text-primary px-2 py-1 text-sm">Welfare Loan</th>
                <th className="text-primary px-2 py-1 text-sm">
                  Saving to Bank
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  Sanasa Member
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  Sanasa Advance
                </th>
                <th className="text-primary px-2 py-1 text-sm">Donations</th>
                <th className="text-primary px-2 py-1 text-sm">
                  Other Deductions 3
                </th>
                <th className="text-primary px-2 py-1 text-sm">Bank Loan 02</th>
                <th className="text-primary px-2 py-1 text-sm">
                  Distress Loan
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  Distress Loan Interest
                </th>
                <th className="text-primary px-2 py-1 text-sm">
                  Total Deductions
                </th>
                <th className="text-primary px-2 py-1 text-sm">Net Salary</th>
                <th className="text-primary px-2 py-1 text-sm">EPF 12%</th>
                <th className="text-primary px-2 py-1 text-sm">ETF 3%</th>
                <th className="text-primary px-2 py-1 text-sm">EPF 20%</th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={item.id}>
                  <td className="bg-white px-2 py-1 text-sm">{index + 1}</td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.employee_no}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">{item.month}</td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.generate_year}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.basic_salary}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.ajt_allowance}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.cost_of_allowance}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.sladc_allowance}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.salary_arrears}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.traveling_fuel}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.spe_allowance_01}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.difficult_allowance}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.act_allowance}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.allowance_tp}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.acct_allo_arre}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.spe_allowance_02}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.language_allowance}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.total_allowances}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.gross_pay}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">{item.no_pay}</td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.no_pay_amount}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.gross_pay_after_no_pay}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.gross_pay_for_epf}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.salary_for_paye}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.epf_8_percent}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">{item.pay_tax}</td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.stamp_duty}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.salary_advance}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.welfare_members}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.festival_advance}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.bank_loan_01}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.other_deductions_1}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.welfare_advance}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">{item.union_1}</td>
                  <td className="bg-white px-2 py-1 text-sm">{item.union_2}</td>
                  <td className="bg-white px-2 py-1 text-sm">{item.union_3}</td>
                  <td className="bg-white px-2 py-1 text-sm">{item.union_4}</td>
                  <td className="bg-white px-2 py-1 text-sm">{item.union_5}</td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.buddha_sangamaya_members}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.sanasa_loan}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.other_deductions_2}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.welfare_loan}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.saving_to_bank}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.sanasa_member}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.sanasa_advance}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.donations}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.other_deductions_3}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.bank_loan_02}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.distress_loan}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.distress_loan_interest}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.total_deductions}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.net_salary}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.epf_12_percent}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.etf_3_percent}
                  </td>
                  <td className="bg-white px-2 py-1 text-sm">
                    {item.epf_20_percent}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 relative">
            <button
              onClick={closeModal}
              className="absolute top-0 right-0 mt-4 mr-4 text-gray-600"
            >
              &times;
            </button>
            <h2 className="text-2xl mb-4">Generate Monthly Payroll</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Select Year
              </label>
              <select
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={generateYear}
                onChange={(e) => setGenerateYear(e.target.value)}
              >
                <option value="">Select Year</option>
                {Array.from({ length: 10 }, (_, i) => {
                  const year = new Date().getFullYear() - i;
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Select Month
              </label>
              <select
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={generateMonth}
                onChange={(e) => setGenerateMonth(e.target.value)}
              >
                <option value="">Select Month</option>
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-end">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="bg-primary text-white px-4 py-2 rounded"
                onClick={openConfirmModal}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}

      <div>
        {isConfirmModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg p-8 relative">
              <button
                onClick={closeConfirmModal}
                className="absolute top-0 right-0 mt-4 mr-4 text-gray-600"
              >
                &times;
              </button>
              <h2 className="text-2xl mb-4">Confirm Generation</h2>
              <p className="mb-4">
                Are you sure you want to generate the monthly payroll for{" "}
                {generateMonth}/{generateYear}?
              </p>
              <div className="flex justify-end">
                <button
                  className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                  onClick={closeConfirmModal}
                >
                  Cancel
                </button>
                <button
                  className="bg-primary text-white px-4 py-2 rounded"
                  onClick={handleConfirmGenerate}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MonthEndPayroll;
