import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";

const Incentivepayroll = () => {
  const [employeeId, setEmployeeId] = useState("");
  const [payrollData, setPayrollData] = useState([]);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [generateYear, setGenerateYear] = useState("");
  const [generateMonth, setGenerateMonth] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = async () => {
    const endpoint = `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/final-incentive?year=${year}&month=${month}`;
    try {
      const response = await fetch(endpoint);
      if (response.ok) {
        const data = await response.json();
        setPayrollData(data);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch payroll data:", errorData);
      }
    } catch (error) {
      console.error("Error occurred while fetching payroll data:", error);
    }
  };

  const handleFetchData = () => {
    if (year && month) {
      fetchData();
    } else {
      alert("Please select both year and month.");
    }
  };

  const filteredData = payrollData.filter((item) =>
    item.employee_no.includes(employeeId)
  );

  const handleGenerate = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openConfirmModal = () => {
    setIsModalOpen(false);
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
    setErrorMessage("");
  };

  const handleConfirmGenerate = async () => {
    const endpoint = `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/calculate-final-incentive`;
    const body = {
      month: generateMonth,
      year: generateYear,
    };

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const data = await response.json();

      if (response.ok) {
        console.log("Payroll generated successfully:", data);
        closeConfirmModal();
        fetchData();
      } else {
        console.error("Failed to generate payroll:", data.error);
        setErrorMessage(data.error || "Failed to generate payroll.");
      }
    } catch (error) {
      console.error("Error occurred while generating payroll:", error);
      setErrorMessage("Error occurred while generating payroll.");
    }
  };
  const exportToExcel = async () => {
    if (!month || !year) {
      setErrorMessage("Please select both year and month before exporting.");
      return;
    }

    const endpoint = `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/final-incentive?year=${year}&month=${month}`;
    try {
      const response = await fetch(endpoint);
      if (response.ok) {
        const data = await response.json();
        const exportData = data.map((item) => ({
          EmployeeNo: item.employee_no,
          Month: item.month,
          Year: item.year,
          CalculatedDate: item.calculated_date,
          ProductionValue: item.production_value,
          TableCValue: item.table_c_value,
          ProductionIncentive: item.production_incentive,
          SectionIncentive: item.section_incentive,
          SLABIncentive: item.slab_incentive,
          SpecialIncentive: item.special_incentive,
          AttendanceIncentive: item.attendance_incentive,
          OvertimeIncentive: item.overtime_incentive,
          ProductionAllowancesTotal: item.production_allowances_total,
          LeaveDeductionsTotal: item.leave_deductions_total,
          TotalLeaveAmount: item.total_leave_amount,
          LeaveDeductionPercent: item.leave_deduction_percent,
          CalculatedLeaveDeductionAmount:
            item.calculated_leave_deduction_amount,
          FinalAmount: item.final_amount,
        }));

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Payroll Data");

        // Generate Excel file and download
        XLSX.writeFile(workbook, `PayrollData_${month}_${year}.xlsx`);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch payroll data:", errorData);
      }
    } catch (error) {
      console.error("Error occurred while fetching payroll data:", error);
    }
  };
  return (
    <div className="font-sans bg-background fixed h-screen">
      <div>
        <p className="mt-6 ml-6 text-headerColor text-[30px] font-semibold">
          Incentive Payroll
        </p>
      </div>
      <div className="flex justify-end">
        <div className="bg-[#5AB2FF] rounded-[22px] h-[189px] w-[430px] mr-[15%]">
          <div className="text-white font-bold ml-4 mt-4 text-[29px]">
            <p>Generate</p>
            <p>Incentive</p>
          </div>
          <div className="flex items-center justify-between">
            <button
              type="button"
              className="bg-white mt-5 ml-4 p-2 rounded-[32px] text-[#5AB2FF] font-bold cursor-pointer w-[151px] h-[37px]"
              onClick={handleGenerate}
            >
              Generate
            </button>
            <div className="mr-5">
              <div className="bg-white rounded-[32px] h-[37px] w-[13px]"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="ml-6 mt-4">
        <div className="flex flex-col font-poppins font-bold">
          <form className="form relative mb-4">
            <input
              type="text"
              required=""
              placeholder="Search Employee ID"
              className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-black focus:outline-none focus:border-blue-500 placeholder-gray-400"
              value={employeeId}
              onChange={(e) => setEmployeeId(e.target.value)}
            />
          </form>
          <div className="flex mb-4">
            <select
              className="input rounded-full w-[200px] h-[5%] px-8 py-3 border-2 border-black focus:outline-none focus:border-blue-500 placeholder-gray-400 mr-4"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="">Select Year</option>
              {Array.from({ length: 10 }, (_, i) => {
                const year = new Date().getFullYear() - i;
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>
            <select
              className="input rounded-full w-[200px] h-[5%] px-8 py-3 border-2 border-black focus:outline-none focus:border-blue-500 placeholder-gray-400"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
            >
              <option value="">Select Month</option>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
            <button
              className="bg-primary text-white px-4 py-2 rounded-full ml-4"
              onClick={handleFetchData}
            >
              Get Incentive Payroll Data
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-start ml-5">
        <button
          type="button"
          className="bg-[#5AB2FF] mb-5 mt-5 mr-6 p-2 rounded-[32px] text-white text-bold cursor-pointer w-[151px] h-[42px]"
          onClick={exportToExcel}
        >
          Export to Excel
        </button>{" "}
      </div>
      <div className="flex justify-end mr-7">
        {errorMessage && (
          <div className="text-red-500 font-semibold">{errorMessage}</div>
        )}
      </div>
      <div className="flex flex-col font-header font-bold ml-6 mt-[25px] rounded-xl mr-[20%] overflow-y-auto">
        <table className="table-auto border-none rounded-3xl border-2 ">
          <thead className="sticky top-0">
            <tr className="bg-white rounded-xl">
              <th className="text-primary px-4 py-2">EMP NO</th>
              <th className="text-primary px-4 py-2">Month</th>
              <th className="text-primary px-4 py-2">Year</th>
              <th className="text-primary px-4 py-2">Calculated Date</th>
              <th className="text-primary px-4 py-2">Production Value</th>
              <th className="text-primary px-4 py-2">Table C Value</th>
              <th className="text-primary px-4 py-2">Production Incentive</th>
              <th className="text-primary px-4 py-2">Section Incentive</th>
              <th className="text-primary px-4 py-2">SLAB Incentive</th>
              <th className="text-primary px-4 py-2">Special Incentive</th>
              <th className="text-primary px-4 py-2">Attendance Incentive</th>
              <th className="text-primary px-4 py-2">Overtime Incentive</th>
              <th className="text-primary px-4 py-2">
                Production Allowances Total
              </th>
              <th className="text-primary px-4 py-2">Leave Deductions Total</th>
              <th className="text-primary px-4 py-2">Total Leave Amount</th>
              <th className="text-primary px-4 py-2">
                Leave Deduction Percent
              </th>
              <th className="text-primary px-4 py-2">
                Calculated Leave Deduction Amount
              </th>
              <th className="text-primary px-4 py-2">Final Amount</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={item.id}>
                <td className="bg-white px-4 py-2">{item.employee_no}</td>
                <td className="bg-white px-4 py-2">{item.month}</td>
                <td className="bg-white px-4 py-2">{item.year}</td>
                <td className="bg-white px-4 py-2">{item.calculated_date}</td>
                <td className="bg-white px-4 py-2">{item.production_value}</td>
                <td className="bg-white px-4 py-2">{item.table_c_value}</td>
                <td className="bg-white px-4 py-2">
                  {item.production_incentive}
                </td>
                <td className="bg-white px-4 py-2">{item.section_incentive}</td>
                <td className="bg-white px-4 py-2">{item.slab_incentive}</td>
                <td className="bg-white px-4 py-2">{item.special_incentive}</td>
                <td className="bg-white px-4 py-2">
                  {item.attendance_incentive}
                </td>
                <td className="bg-white px-4 py-2">
                  {item.overtime_incentive}
                </td>
                <td className="bg-white px-4 py-2">
                  {item.production_allowances_total}
                </td>
                <td className="bg-white px-4 py-2">
                  {item.leave_deductions_total}
                </td>
                <td className="bg-white px-4 py-2">
                  {item.total_leave_amount}
                </td>
                <td className="bg-white px-4 py-2">
                  {item.leave_deduction_percent}
                </td>
                <td className="bg-white px-4 py-2">
                  {item.calculated_leave_deduction_amount}
                </td>
                <td className="bg-white px-4 py-2">{item.final_amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 relative">
            <button
              onClick={closeModal}
              className="absolute top-0 right-0 mt-4 mr-4 text-gray-600"
            >
              &times;
            </button>
            <h2 className="text-2xl mb-4">Generate Incentive data Payroll</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Select Year
              </label>
              <select
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={generateYear}
                onChange={(e) => setGenerateYear(e.target.value)}
              >
                <option value="">Select Year</option>
                {Array.from({ length: 10 }, (_, i) => {
                  const year = new Date().getFullYear() - i;
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Select Month
              </label>
              <select
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={generateMonth}
                onChange={(e) => setGenerateMonth(e.target.value)}
              >
                <option value="">Select Month</option>
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-end">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="bg-primary text-white px-4 py-2 rounded"
                onClick={openConfirmModal}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
      <div>
        {isConfirmModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg p-8 relative">
              <button
                onClick={closeConfirmModal}
                className="absolute top-0 right-0 mt-4 mr-4 text-gray-600"
              >
                &times;
              </button>
              <h2 className="text-2xl mb-4">Confirm Generation</h2>
              {errorMessage && (
                <p className="text-red-500 mb-4">{errorMessage}</p>
              )}
              <p className="mb-4">
                Are you sure you want to generate the incentive payroll for{" "}
                {generateMonth}/{generateYear}?
              </p>
              <div className="flex justify-end">
                <button
                  className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                  onClick={closeConfirmModal}
                >
                  Cancel
                </button>
                <button
                  className="bg-primary text-white px-4 py-2 rounded"
                  onClick={handleConfirmGenerate}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Incentivepayroll;
