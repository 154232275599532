import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Navbar from "../navbar/navbar";

const PayrollNav = () => {
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  return (
    <div className="bg-background  h-screen mx-5 mt-5">
      <div className="mr-[10%]">
        <div>
          <Navbar />
        </div>
        <div className="flex justify-between items-center">
          <p className="text-[30px] ml-6 mt-6 font-semibold text-black">
            Payroll Management
          </p>
          <div className="flex gap-6 items-center">
            <div>
              <div className="text-[#3D0B5E] text-[20px] font-bold">
                {currentDate}
              </div>
            </div>
            <div className="text-[20px] font-bold">{currentTime}</div>
          </div>
        </div>
      </div>
      <div className="flex ml-8 gap-12">
        <Link to="/monthend-payroll">
          <div className="bg-white rounded-[32px] h-[100px] w-[450px] p-4 shadow-lg flex flex-col justify-between mt-[2.5%] ">
            <div className="flex justify-between items-center">
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Month-end Payroll
              </h2>
              <button className="bg-[#5AB2FF] w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                Genarate{" "}
              </button>
            </div>
            <div className="flex justify-between items-center ml-3">
              <div className="bg-[#5AB2FF] w-[37px] h-[13px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/incentive">
          <div className="bg-white rounded-[32px] h-[100px] w-[450px] p-4 shadow-lg flex flex-col justify-between mt-[2.5%] ">
            <div className="flex justify-between items-center">
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Incentive Payroll
              </h2>
              <button className="bg-[#5AB2FF] w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                Genarate{" "}
              </button>
            </div>
            <div className="flex justify-between items-center ml-3">
              <div className="bg-[#5AB2FF] w-[37px] h-[13px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/generated-payroll">
          <div className="bg-white rounded-[32px] h-[100px] w-[450px] p-4 shadow-lg flex flex-col justify-between mt-[2.5%] ">
            <div className="flex justify-between items-center">
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Generated Payroll{" "}
              </h2>
              <button className="bg-[#5AB2FF] w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                Management{" "}
              </button>
            </div>
            <div className="flex justify-between items-center ml-3">
              <div className="bg-[#5AB2FF] w-[37px] h-[13px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[2.5%] mx-6 font-sans mb-8">
        <Link to="/allowances">
          <div className="bg-white rounded-[32px] h-[200px] w-[450px] p-4 shadow-lg flex flex-col justify-between">
            <div>
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Payroll
              </h2>
              <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                Allowances
              </h2>
            </div>
            <div className="flex justify-between items-center">
              <button className="bg-[#5AB2FF] w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                Click Here
              </button>
              <div className="bg-[#5AB2FF] w-[13px] h-[37px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/dedutions">
          <div className="bg-white rounded-[32px] h-[200px] w-[450px] p-4 shadow-lg flex flex-col justify-between">
            <div>
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Payroll
              </h2>
              <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                Deductions
              </h2>
            </div>
            <div className="flex justify-between items-center">
              <button className="bg-[#5AB2FF] w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                Click Here
              </button>
              <div className="bg-[#5AB2FF] w-[13px] h-[37px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/production_value">
          <div className="bg-white rounded-[32px] h-[200px] w-[450px] p-4 shadow-lg flex flex-col justify-between">
            <div>
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Production
              </h2>
              <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                Value
              </h2>
            </div>
            <div className="flex justify-between items-center">
              <button className="bg-[#5AB2FF] w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                Click Here
              </button>
              <div className="bg-[#5AB2FF] w-[13px] h-[37px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/section-incentive">
          <div className="bg-white rounded-[32px] h-[200px] w-[450px] p-4 shadow-lg flex flex-col justify-between">
            <div>
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Section
              </h2>
              <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                Incentive
              </h2>
            </div>
            <div className="flex justify-between items-center">
              <button className="bg-[#5AB2FF] w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                Click Here
              </button>
              <div className="bg-[#5AB2FF] w-[13px] h-[37px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/slab">
          <div className="bg-white rounded-[32px] h-[200px] w-[450px] p-4 shadow-lg flex flex-col justify-between">
            <div>
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                SLAB
              </h2>
            </div>
            <div className="flex justify-between items-center">
              <button className="bg-[#5AB2FF] w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                Click Here
              </button>
              <div className="bg-[#5AB2FF] w-[13px] h-[37px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/special-incentive">
          <div className="bg-white rounded-[32px] h-[200px] w-[450px] p-4 shadow-lg flex flex-col justify-between">
            <div>
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Special Incentive
              </h2>
            </div>
            <div className="flex justify-between items-center">
              <button className="bg-[#5AB2FF] w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                Click Here
              </button>
              <div className="bg-[#5AB2FF] w-[13px] h-[37px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/overtime">
          <div className="bg-white rounded-[32px] h-[200px] w-[450px] p-4 shadow-lg flex flex-col justify-between">
            <div>
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Overtime
              </h2>
            </div>
            <div className="flex justify-between items-center">
              <button className="bg-[#5AB2FF] w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                Click Here
              </button>
              <div className="bg-[#5AB2FF] w-[13px] h-[37px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/leave-amount-management">
          <div className="bg-white rounded-[32px] h-[200px] w-[450px] p-4 shadow-lg flex flex-col justify-between">
            <div>
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Leave Amount
              </h2>
              <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                Management
              </h2>
            </div>
            <div className="flex justify-between items-center">
              <button className="bg-[#5AB2FF] w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                Click Here
              </button>
              <div className="bg-[#5AB2FF] w-[13px] h-[37px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default PayrollNav;
