/** @format */

import React, { useState, useEffect } from "react";
import usePermissions from "../../../../components/permissions/permission";

const MonthEndPayroll = () => {
  const { hasPermission } = usePermissions();
  const [employeeId, setEmployeeId] = useState("");
  const [payrollData, setPayrollData] = useState([]);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [generateYear, setGenerateYear] = useState("");
  const [generateMonth, setGenerateMonth] = useState("");

  const fetchData = async () => {
    const endpoint = `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/generateleaveamountsbymonthyear?year=${year}&month=${month}`;
    try {
      const response = await fetch(endpoint);
      if (response.ok) {
        const data = await response.json();
        setPayrollData(data);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch payroll data:", errorData);
      }
    } catch (error) {
      console.error("Error occurred while fetching payroll data:", error);
    }
  };

  const handleFetchData = () => {
    if (year && month) {
      fetchData();
    } else {
      alert("Please select both year and month.");
    }
  };

  const filteredData = payrollData.filter((item) =>
    item.employee_no.includes(employeeId)
  );

  const handleGenerate = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openConfirmModal = () => {
    setIsModalOpen(false);
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirmGenerate = async () => {
    const endpoint = `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/generate-leave-amount`;
    const body = {
      month: generateMonth,
      year: generateYear,
    };

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Leave amounts generated successfully:", data);
        closeConfirmModal();
        fetchData();
      } else {
        const errorData = await response.json();
        console.error("Failed to generate leave amounts:", errorData);
      }
    } catch (error) {
      console.error("Error occurred while generating leave amounts:", error);
    }
  };

  return (
    <div className="font-sans bg-background overflow-auto h-screen">
      <div>
        <p className="mt-6 ml-6 text-headerColor text-[30px] font-semibold">
          Leave Amount Management
        </p>
      </div>
      <div className="flex justify-end">
        {hasPermission(2062) && (
          <div className="bg-white rounded-[22px] h-[189px] w-[430px] mr-[5%]">
            <div className="text-[#797C80] font-bold ml-4 mt-4 text-[29px]">
              <p>Calculate</p>
              <p>Leave Amounts</p>
            </div>
            <div className="flex items-center justify-between">
              <button
                type="button"
                className="bg-primary mt-5 ml-4 p-2 rounded-[32px] text-white cursor-pointer w-[151px] h-[37px]"
                onClick={handleGenerate}
              >
                + Calculate
              </button>
              <div className="mr-5">
                <div className="bg-primary rounded-[32px] h-[37px] w-[13px]"></div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="ml-6 mt-4">
        <div className="flex flex-col font-poppins font-bold">
          <form className="form relative mb-4">
            <input
              type="text"
              required=""
              placeholder="Search Employee ID"
              className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-black focus:outline-none focus:border-blue-500 placeholder-gray-400"
              value={employeeId}
              onChange={(e) => setEmployeeId(e.target.value)}
            />
          </form>
          <div className="flex mb-4">
            <select
              className="input rounded-full w-[200px] h-[5%] px-8 py-3 border-2 border-black focus:outline-none focus:border-blue-500 placeholder-gray-400 mr-4"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="">Select Year</option>
              {Array.from({ length: 10 }, (_, i) => {
                const year = new Date().getFullYear() - i;
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>
            <select
              className="input rounded-full w-[200px] h-[5%] px-8 py-3 border-2 border-black focus:outline-none focus:border-blue-500 placeholder-gray-400"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
            >
              <option value="">Select Month</option>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
            <button
              className="bg-[#5AB2FF] text-white px-4 py-2 rounded-full ml-4"
              onClick={handleFetchData}
            >
              Get Leave Amount Data
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col font-header font-bold ml-6 mt-[50px] rounded-xl">
        <table className="table-auto border-none w-[95%] rounded-3xl border-2">
          <thead className="sticky top-0 bg-white">
            <tr className="bg-gray-300 rounded-xl">
              <th className="text-primary px-4 py-2">EMP NO</th>
              <th className="text-primary px-4 py-2">Month</th>
              <th className="text-primary px-4 py-2">Year</th>
              <th className="text-primary px-4 py-2">Incentive Amount</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={item.id} className="bg-white">
                <td className="px-4 py-2 text-center">{item.employee_no}</td>
                <td className="px-4 py-2 text-center">{item.month}</td>
                <td className="px-4 py-2 text-center">{item.year}</td>
                <td className="px-4 py-2 text-center">
                  {item.incentive_amount}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 relative">
            <button
              onClick={closeModal}
              className="absolute top-0 right-0 mt-4 mr-4 text-gray-600"
            >
              &times;
            </button>
            <h2 className="text-2xl mb-4">Generate Leave Amounts</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Select Year
              </label>
              <select
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={generateYear}
                onChange={(e) => setGenerateYear(e.target.value)}
              >
                <option value="">Select Year</option>
                {Array.from({ length: 10 }, (_, i) => {
                  const year = new Date().getFullYear() - i;
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Select Month
              </label>
              <select
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={generateMonth}
                onChange={(e) => setGenerateMonth(e.target.value)}
              >
                <option value="">Select Month</option>
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-end">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="bg-primary text-white px-4 py-2 rounded"
                onClick={openConfirmModal}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}

      {isConfirmModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 relative">
            <button
              onClick={closeConfirmModal}
              className="absolute top-0 right-0 mt-4 mr-4 text-gray-600"
            >
              &times;
            </button>
            <h2 className="text-2xl mb-4">Confirm Generation</h2>
            <p className="mb-4">
              Are you sure you want to generate the leave amount for{" "}
              {generateMonth}/{generateYear}?
            </p>
            <div className="flex justify-end">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                onClick={closeConfirmModal}
              >
                Cancel
              </button>
              <button
                className="bg-primary text-white px-4 py-2 rounded"
                onClick={handleConfirmGenerate}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MonthEndPayroll;
