import React from "react";
import { FaBell } from "react-icons/fa";
import Cookies from "js-cookie";
import Hi from "../../../assets/hi.png";

const Navbar = () => {
  const user = Cookies.get("employee_calling_name");

  const getGreeting = () => {
    const now = new Date();
    const hour = now.getHours();
  
    if (hour < 12) {
      return "Good Morning 🌅"; 
    } else if (hour < 17) {
      return "Good Afternoon 🌞"; 
    } else {
      return "Good Evening 🌙"; 
    }
  };
  

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <div className="flex items-center">
            <p className="text-[20px] font-bold">
              {getGreeting()}, {user}
            </p>
            <img src={Hi} alt="hi" className="w-10 h-8 mb-5" />
          </div>
        </div>
        <div>
          <div className="flex gap-4 items-center">
            <FaBell />
            <div>
              <p className="text-[20px] font-semibold">{user}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
